import axios from 'axios'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'

import {
  DEFAULT_SELLER_NUMBER,
  PDP_SHIPPING_OPTIONS_TEXTS,
} from '../../constants'

type Items = {
  id?: string
  quantity: number
  seller: string
}

type CacheID = string

type CacheData = CacheID

const CACHE: Record<CacheData, ShippinngSLAItem[]> = {}

const shippingSimulation = async (
  items: Items[],
  geoCoordinates: number[] | undefined,
  setSlas: Dispatch<SetStateAction<ShippinngSLAItem[] | []>>
) => {
  if (items.length === 0 || geoCoordinates?.length === 0) {
    return
  }

  const variables = {
    items,
    geoCoordinates,
    country: 'URY',
  }

  const cacheID = `shippingSimulation-${items[0]?.id}`

  if (CACHE[cacheID] !== undefined) {
    setSlas(CACHE[cacheID])
  } else {
    await axios
      .post('/api/simulation', variables)
      .then((response) => {
        CACHE[cacheID] = response.data.logisticsInfo[0].slas
        setSlas(response.data.logisticsInfo[0].slas)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export const useShippingSimulation = (
  sku: string,
  sellerIdentifier: string,
  pdpPage = true
) => {
  const [slas, setSlas] = useState<ShippinngSLAItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    /* Get sellerData in Browser Cookie */
    if (
      !localStorage.getItem(
        PDP_SHIPPING_OPTIONS_TEXTS.shippingLocalStorageKey
      ) ||
      !sku ||
      !sellerIdentifier
    ) {
      return
    }

    if (!pdpPage && sellerIdentifier === DEFAULT_SELLER_NUMBER) {
      return
    }

    const localStorageValue = JSON.parse(
      localStorage.getItem(
        PDP_SHIPPING_OPTIONS_TEXTS.shippingLocalStorageKey
      ) ?? '{}'
    )

    const geoCoordinates = localStorageValue?.geoCoordinates

    const items = [
      {
        id: sku,
        quantity: 1,
        seller: sellerIdentifier,
      },
    ]

    /* Execute Shipping Simulation to get SLAs */
    shippingSimulation(items, geoCoordinates, setSlas).then(() =>
      setLoading(false)
    )
  }, [sellerIdentifier, sku, pdpPage])

  const delivery = slas?.filter(
    (item: ShippinngSLAItem) =>
      item.deliveryChannel === PDP_SHIPPING_OPTIONS_TEXTS.shippingTypeDelivery
  )

  const pickupoint = slas?.filter(
    (item: ShippinngSLAItem) =>
      item.deliveryChannel === PDP_SHIPPING_OPTIONS_TEXTS.shippingTypePickup
  )

  const isShippingSameDay = slas.some((sla) => sla.id === 'ED')

  const isShippingSameDayAndFree = slas.some(
    (sla) => sla.id === 'ED' && sla.price === 0
  )

  return {
    delivery,
    pickupoint,
    loading,
    isShippingSameDayAndFree,
    isShippingSameDay,
  }
}
